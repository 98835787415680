import { useNavigate } from 'react-router-dom'

function ContentCard({ model }) {
  const navigate = useNavigate()

  let iconContainerCss = "bg-lightblue"
  let iconCss = "text-primary"
  if (!model.enabled) {
    iconCss = "text-border-grey"
    iconContainerCss = "bg-bg-grey"
  }

  return (
    <button
      key={model.name} onClick={() => navigate(model.path)} disabled={!model.enabled}
      className={`flex flex-col px-3 py-4 bg-white justify-content-center items-center rounded hover:cursor-pointer disabled:cursor-not-allowed`}>
      <div className={`px-3 py-1 rounded-md ${iconContainerCss} flex flex-col justify-content-center items-center`}>
        <i className={`${model.icon} text-[40px] ${iconCss}`}></i>
      </div>
      <div className="text-base font-bold">{model.name}</div>
      <div className="text-sm text-tertiary px-1 mt-2 max-w-[300px] text-center">{model.description}</div>
    </button>
  )

}

export default ContentCard