import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material";

const filter = createFilterOptions();

const AutoCompletePopupStyled = styled(Autocomplete)(() => ({
  [`& .MuiAutocomplete-inputRoot`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  [`&.Mui-focused fieldset`]: {
    borderColor: `#017A49!important`,
  },
}));

const AutoCompleteDefaultStyled = styled(Autocomplete)(() => ({
  [`&.Mui-focused fieldset`]: {
    borderColor: `#017A49!important`,
  },
}));

export default function SearchableDropDown({
  id,
  options = [],
  onChangeHandler,
  placeholder = "Search...",
  open = true,
  PopperComponent,
  popupStyleComponent = false,
  disabled = false,
  otherStyleProps = {},
}) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const ComponentToRender = popupStyleComponent
    ? AutoCompletePopupStyled
    : AutoCompleteDefaultStyled;

  return (
    <ComponentToRender
      id={id}
      disabled={disabled}
      {...(open ? { open: open } : {})}
      freeSolo
      value={value}
      inputValue={inputValue}
      options={options}
      sx={{
        width: '50%',
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #017A49 !important'
          },
          '&.Mui-focused fieldset': {
            border: '2px solid #017A49 !important'
          }
        },
        ...otherStyleProps
      }}
      ListboxProps={{ style: { maxHeight: 200 } }}
      onChange={(event, newValue) => {
        if (event.code != "Enter") {
          setValue(newValue);
          if (onChangeHandler) {
            onChangeHandler(newValue);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(options, params) => {
        return filter(options, params);
      }}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.text;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={props.id}>
            {option.text}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
      {...(PopperComponent ? { PopperComponent } : {})}
    />
  );
}
