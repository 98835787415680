const HUMAN_MESSAGE_TYPE = 'human'
const AI_MESSAGE_TYPE = 'ai'
const PUBLIC_ACCESS = 'public'
const PRIVATE_ACCESS = 'private'
const PRESET_API_KEY = 'test_key'
const TEST_KEY_OPTION_VALUE_MODEL_SETTINGS = 'Use Test Key'
const SELF_KEY_OPTION_VALUE_MODEL_SETTINGS = 'Self'
const LLM_MODEL_VALUE_V1 = 'gpt-3.5-turbo'
const LLM_MODEL_VALUE_V2 = 'gpt-4'
const AI_LABEL = 'nü(AI)de'
const USER_LABEL = 'User'
const MEDICAL_CODING_MAPPING = {
  medicalConcept: 'Medical Concept',
  sentiment: 'Sentiment',
  history: 'History',
  experiencer: 'Experiencer',
  snomedctCode: 'Snomedct Code',
  icd10Code: 'ICD10 Code',
}

const STEP_STATUS_ACTIVE = 'active'
const STEP_STATUS_COMPLETED = 'completed'

export {
  HUMAN_MESSAGE_TYPE,
  AI_MESSAGE_TYPE,
  PUBLIC_ACCESS,
  PRIVATE_ACCESS,
  PRESET_API_KEY,
  TEST_KEY_OPTION_VALUE_MODEL_SETTINGS,
  SELF_KEY_OPTION_VALUE_MODEL_SETTINGS,
  LLM_MODEL_VALUE_V1,
  LLM_MODEL_VALUE_V2,
  AI_LABEL,
  USER_LABEL,
  MEDICAL_CODING_MAPPING,
  STEP_STATUS_ACTIVE,
  STEP_STATUS_COMPLETED
}