function CustomButton({ buttonType = 'primary', additionalStyle = '', children, onClick = null, rounded = 'rounded-md', ...props }) {
  let style
  switch (buttonType) {
    case 'primary':
      style = `bg-primary hover:bg-primary-dark active:bg-primary-darker focus:bg-primary disabled:bg-grey hover:cursor-pointer text-white border ${rounded} border-primary disabled:border-grey`
      break;
    case 'secondary':
      style = `bg-white hover:bg-primary-light active:bg-primary-light focus:bg-white disabled:bg-grey hover:cursor-pointer text-primary border ${rounded} border-primary disabled:border-grey`
      break;
    case 'secondary-icon':
      style = `text-primary text-2xl w-[40px] h-[40px] hover:cursor-pointer rounded border border-primary bg-white hover:bg-secondary-green active:bg-secondary-green focus:bg-white`
      break;
    case 'tertiary-icon':
      style = `text-primary text-2xl w-[40px] h-[40px] hover:cursor-pointer rounded border border-transparent bg-white hover:bg-secondary-green active:bg-secondary-green focus:bg-white focus:bg-primary focus:border-2`
      break;
    case 'disabled':
      style = `text-grey text-2xl w-[40px] h-[40px] hover:cursor-not-allowed rounded border border-red bg-white `
    case 'disabled-secondary':
      style = `text-grey text-2xl w-[40px] h-[40px] hover:cursor-not-allowed rounded bg-white`
    default:
      break;
  }
  return (
    <button
      {...props}
      className={additionalStyle + ' ' + style}
      onClick={onClick}
      disabled={props.disabled}
    >
      {children}
    </button>
  )
}

export default CustomButton