import parse from 'html-react-parser'
import AuthenticatedLayout from '../../components/layouts/AuthenticatedLayout'
import { getCareModels, getIntroForHomePage } from '../../api/util';
import { useEffect, useState } from 'react';
import ContentCard from '../../components/ContentCard';
const initialState = { careModels: [], intro: '' }

function HomePage() {
  const [state, setState] = useState(initialState)
  useEffect(function () {
    async function fetchData() {
      const careModelsResponse = await getCareModels()
      setState({ ...state, careModels: careModelsResponse})
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function getSectionGrid(section) {
    return section.list.map(function (model) {
      return (
        <ContentCard model={model} />
      )
    })
  }

  function getSections() {
    return state.careModels.map(function (section) {
      const careModels = getSectionGrid(section)
      return (
        <div key={section.name}>
          <div key={section.name} className="mt-4 text-sm font-bold">{section.name}</div>
          <div className="grid grid-cols-3 gap-8 mt-1">
            {careModels}
          </div>
        </div>
      )
    })
  }

  const careModelsElements = getSections()

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col bg-lightgrey py-12 px-8 max-h-[calc(100vh-90px)] w-full">
        <div className="text-heading">Context Learned Configurable AI Solution</div>
        {careModelsElements}
      </div>
    </AuthenticatedLayout>
  )
}

export default HomePage;