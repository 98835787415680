import { crmcAxiosInstance } from '../lib/axios'
import { parseJsonString } from '../lib/helper';
import {
  TRIAGE_LEVEL_URL,
  TRIAGE_QUESTIONS_V1_URL,
  TRIAGE_LEVEL_V1_URL
} from '../lib/api-urls'

async function getTriageQuestionsV1({ age, gender, mainComplaint }) {
  try {
    const response = await crmcAxiosInstance.post(TRIAGE_QUESTIONS_V1_URL, {
      model_platform: "openai",
      model_temperature: 0,
      prompt_key: "NUHEALTH_MEDICAL_REG_QUESTIONS",
      model_name: "gpt-4o",
      input_variables: {
        context: `Complaint: ${mainComplaint}\nAge: ${age}\nGender: ${gender}`,
      },
      session_id: sessionStorage.getItem("sessionId"),
    })

    return {
      data: parseJsonString(response.data.text)
    }
  } catch (e) {
    return e
  }
}

async function getTriageLevelV1({ patientInfo }) {
  try {
    const response = await crmcAxiosInstance.post(TRIAGE_LEVEL_V1_URL, {
      model_platform: "openai",
      model_temperature: 0,
      prompt_key: "NUHEALTH_MEDICAL_TRIAGE_LEVEL",
      model_name: "gpt-4o",
      input_variables: {
        context: patientInfo
      },
      session_id: sessionStorage.getItem("sessionId"),
    })
    return {
      data: parseJsonString(response.data.text)
    }
  } catch (e) {
    return e
  }
}

export {
  getTriageQuestionsV1,
  getTriageLevelV1
}