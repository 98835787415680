import React, { useEffect, useState } from "react";
import { handleLocalLogin, handleLogin, isTokenValid } from "../auth";
import NuscaleLogo from "../../assets/images/nuscale-logo.svg";
import OpenLayout from "../../components/layouts/OpenLayout";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  TextField,
  Typography,
  Divider
} from "@mui/material";
import toast from "../../lib/toast";
import { HOME_URL, LS_TOKEN_KEY } from "../config";
import { useNavigate } from "react-router-dom";

const initialState = { username: "", password: "", rememberMe: false };

const Login = () => {
  const [form, setForm] = useState(initialState);
  const navigate = useNavigate();
  // TODO: If valid token GO to Home page
  const isAuthenticated = () => {
    const token = localStorage.getItem(LS_TOKEN_KEY);
    if (token) {
      return isTokenValid(token);
    }
    // No token found
    return false;
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(HOME_URL);
    }
    // eslint-disable-next-line 
  }, []);

  async function submit(e) {
    e.preventDefault();
    const response = await handleLocalLogin({
      username: form.username,
      password: form.password,
    });
    console.log(response.error);
    if (response.error) {
      toast({
        message:
          response?.error?.response?.data?.detail ?? response.error.message,
      });
    } else {
      setForm({ ...form, initialState });
    }
  }

  function handleFieldChange(field, value) {
    setForm({ ...form, [field]: value });
  }

  function handleRememberMe() {
    setForm({ ...form, rememberMe: !form.rememberMe });
  }

  return (
    <OpenLayout>
      {/* <Typography variant="h4">
{"your {data} + our {accelerators} = ∞ (possibilities)"}</Typography> */}
      <Paper
        elevation={14}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingY: "1.25rem",
          px: "2.5rem",
          // mt: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem 2.5rem",
          }}
        >
          <img
            src={NuscaleLogo}
            alt="Nuscale Logo"
            style={{
              width: "312px",
              padding: "1.5rem",
              maxWidth: "100%",
            }}
          />
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            size="medium"
            sx={{
              mt: 1,
              borderRadius: "40px",
              textTransform: "capitalize",
              width: "100%",
            }}
            onClick={handleLogin}
          >
            Login with Nuscale AD
          </Button>
          <Divider component={"div"} sx={{width: '100%', height: '10px'}}/>
          <form onSubmit={submit} style={{width: '100%', marginTop: '10px'}}>
            <div>
              <Typography component="div">Username</Typography>
              <TextField
                variant="outlined"
                type="text"
                name="username"
                value={form.username}
                required
                size="medium"
                sx={{
                  width: "100%",
                  fontSize: "0.875rem",
                }}
                placeholder="Enter Username"
                onChange={(e) => handleFieldChange("username", e.target.value)}
              />
            </div>
            <div>
              <Typography component="div" sx={{ marginTop: "15px" }}>
                Password
              </Typography>
              <TextField
                variant="outlined"
                type="password"
                name="password"
                value={form.password}
                required
                size="medium"
                sx={{
                  width: "100%",
                  fontSize: "0.875rem",
                }}
                placeholder="Enter Password"
                onChange={(e) => handleFieldChange("password", e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.25rem",
                  fontSize: "0.875rem",
                  color: "#666",
                }}
              >
                <Typography component="div">
                  <Checkbox
                    type="checkbox"
                    name="rememberMe"
                    checked={form.rememberMe}
                    onChange={handleRememberMe}
                  />
                  Remember Me
                </Typography>
              </div>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{
                  mt: 1,
                  borderRadius: "40px",
                  textTransform: "capitalize",
                }}
              >
                Login
              </Button>
            </div>
          </form>
        </Box>
      </Paper>
    </OpenLayout>
  );
};

export default Login;
