import parse from 'html-react-parser'
import CustomButton from '../../../components/CustomButton'
import { TriageLevelAttributeMapping } from '../../../lib/triage-level-attribute-mapping'

function TriageResult({ data = {}, patientInfoObject, goToPreviousStep }) {
  function goBack() {
    goToPreviousStep({ previousStepIndex: patientInfoObject.data?.skipAdditionalQuestions === true ? 0 : 1 })
  }

  const { triageLevel = '', explanation = '' } = data
  let triageLevelAttributes
  if (triageLevel) {
    triageLevelAttributes = TriageLevelAttributeMapping[triageLevel || 'default']
  }

  return (
    <div className="flex flex-col grow bg-white rounded border border-near-white">
      <div className="flex flex-row">
        <div className="flex flex-col grow grow px-5 py-5 gap-1">
          <div className="font-bold text-base">Triage Level</div>
          <div className="text-xs text-tertiary">This section summarizes the triage level and it’s reasoning</div>
        </div>
        <div className={`flex flex-row p-5`}>
          <div className={`flex flex-row grow rounded-md border border-lightgrey ${triageLevelAttributes.backgroundColor} ${triageLevelAttributes.textColor}`}>
            <div className="flex flex-col items-center p-3 justify-center">
              <i className={`${triageLevelAttributes.icon} text-2xl`}></i>
            </div>
            <div className="flex flex-col grow text-base px-3 font-bold items-center justify-center">
              {triageLevel}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-h-[calc(100vh-300px)] grow p-4 bg-white border-t border-t-near-white">
        <div className="flex flex-col bg-grey overflow-scroll p-4 gap-2">
          <div className="text-heading-secondary font-bold">
            Suggested Treatment
          </div>
          <div className="text-tertiary">
            {parse(explanation || '')}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end p-4">
        <CustomButton onClick={goBack} buttonType='secondary' additionalStyle="px-4 py-2">Back</CustomButton>
      </div>
    </div>
  )
}

export default TriageResult