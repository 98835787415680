// LOCAL STORE
const LS_TOKEN_KEY = "OAUTH";
const LS_LOCAL_LOGIN_KEY = "LOCAL";

// AUTH API
const AUTH_BACKEND_URL = "https://apps.nuscale.io:8002/nuscale/auth";
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const LOGOUT_REDIRECT_URI = `${window.location.origin}/logout`;

// PATHS
const HOME_URL = "/home";
const LOGIN_CALLBACK_PATH = "/login/callback";
const LOGOUT_PATH = "/logout";
const LOGIN_PATH = "/login";

export {
  LS_TOKEN_KEY,
  LS_LOCAL_LOGIN_KEY,
  AUTH_BACKEND_URL,
  LOGIN_CALLBACK_PATH,
  LOGOUT_PATH,
  LOGIN_PATH,
  REDIRECT_URI,
  LOGOUT_REDIRECT_URI,
  HOME_URL,
};
