import { useEffect, useState } from "react"
import CustomButton from '../../../components/CustomButton'
import { getBTriageQuestionsV1, getDiseasesAndCategories } from '../../../api/behavioral-triage'
import toast from "../../../lib/toast"

const initialState = {
  categorySelected: '',
  diseaseSelected: '',
  age: '',
  gender: '',
  categories: []
}

function BasicDetailsForm({ data, goToNextStep, updateDataForStep, setIsLoading }) {
  const [state, setState] = useState({ ...initialState, ...data })

  useEffect(function () {
    async function getDiseases() {
      const { data, error } = await getDiseasesAndCategories()
      if (error) {
        toast({ message: error.message })
      } else {
        setState({ ...state, categories: data })
      }
    }
    getDiseases()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChange(field, value) {
    setState({ ...state, [field]: value })
    updateDataForStep({ data: { [field]: value } })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    const { data: triageResponse, error } = await getBTriageQuestionsV1({
      category: state.categorySelected,
      disease: state.diseaseSelected,
      age: state.age,
      gender: state.gender
    })
    if (error) {
      toast({ message: error.message })
    } else {
      goToNextStep({
        nextStepIndex: 1,
        nextStepData: {
          data: {
            ...state,
            fields: triageResponse
          }
        }
      })
    }
    setIsLoading(false)
  }
  let selectedCategoryObject = state.categories.find(category => category.category === state.categorySelected)

  return (
    <div className="flex flex-col bg-white rounded border border-near-white">
      <div className="flex flex-col p-4 gap-2">
        <div className="font-bold">Basic Details</div>
        <div className="text-xs text-tertiary">Use this section to specify details of the patient and primary symptoms</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col p-4 gap-4 border-t border-t-near-white">
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium">Category *</label>
            <select
              className="border border-grey rounded py-1 px-2"
              placeholder="Select Disease for Assessment"
              onChange={(e) => handleChange('categorySelected', e.target.value)}
              value={state.categorySelected}
              required
            >
              <option></option>
              {state.categories.map((category) => {
                return (
                  <option value={category.category} key={'category-' + category.category}>{category.category}</option>
                )
              })
              }
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium">Type of evaluation *</label>
            <select
              className="border border-grey rounded py-1 px-2"
              placeholder="Select Disease for Assessment"
              onChange={(e) => handleChange('diseaseSelected', e.target.value)}
              value={state.diseaseSelected}
              required
            >
              <option></option>
              {selectedCategoryObject && selectedCategoryObject.diseases && selectedCategoryObject.diseases.map(function (disease) {
                return (
                  <option value={disease} key={'disease-' + disease}>{disease}</option>
                )
              })
              }
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-body-disabled">About the patient</label>
            <div className="flex flex-row gap-2">
              <div className="flex flex-col basis-1/2 gap-1">
                <label className="text-sm text-tertiary">Gender *</label>
                <select
                  className="border border-grey rounded py-1 px-2"
                  onChange={(e) => handleChange('gender', e.target.value)}
                  value={state.gender}
                  required
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="flex flex-col basis-1/2 gap-1">
                <label className="text-sm text-tertiary">Age *</label>
                <input
                  type="number"
                  min={1}
                  max={100}
                  className="border border-grey rounded py-1 px-2"
                  onChange={(e) => handleChange('age', e.target.value)}
                  value={state.age}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end">
            <CustomButton type="submit" additionalStyle="px-4 py-2">Next</CustomButton>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BasicDetailsForm