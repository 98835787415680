import { crmcAxiosInstance } from '../lib/axios'
import { USERS_URL } from '../lib/api-urls'
import {
  MEDICAL_TRIAGE_PAGE_PATH, BEHAVIORAL_TRIAGE_PAGE_PATH, CHAT_PAGE_PATH,
  SUMMARIZATION_PAGE_PATH, CHARTS_QA_PAGE_PATH, MEDICAL_CODING_PAGE_PATH,
  FRAUD_WASTE_COMPLIANCE_PAGE_PATH, AMBIENT_DICTATION_AUTOMATION_PAGE_PATH,
  PRE_AUTH_ASSESSMENT_PAGE_PATH
} from '../lib/paths'

function getIntroForHomePage() {
  return new Promise(function (resolve, reject) {
    resolve(
      `<div className="flex flex-col grow gap-4">
        <div className="text-4xl font-bold text-near-black">
          Hello,
          <div className="text-primary">James</div>
        </div>
        <div className="flex flex-row">
          <div className="basis-11/12">
            <div className="flex flex-col gap-10">
              <div>This platform helps you discover cutting-edge products harnessing the power of Advanced LLM Technology. It helps you revolutionise patient information and unlock valuable insights!</div>
              <div>If you need to learn about anything related to the product please use this 
              <a href="https://www.nuscale.ai/contact" target="_blank" rel="noreferrer" className="text-secondary hover:text-secondary active:text-secondary hover:no-underline mr-[-3px]">
                help link
              </a>.
              </div>
              <div>You can always write to us if you have questions or if you need additional access at 
              <a href="https://www.nuscale.ai/contact" target="_blank" rel="noreferrer" className="text-secondary hover:text-secondary active:text-secondary hover:no-underline">
                info@nuscale.ai
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>`
    )
  })
}

function getIntroForChatPage() {
  return new Promise(function (resolve, reject) {
    resolve(
      `<div className="flex flex-col items-center">
          <div className="flex flex-col items-center mt-6">
            <div className="p-6 bg-bg-grey rounded-xl border border-border-grey">
              <i class="ri-robot-2-fill text-primary text-5xl"></i>
            </div>
            <div className="text-2xl font-medium text-header-black mt-4">nü(AI)de</div>
            <div className="text-tertiary"><i class="ri-circle-fill text-primary"></i> Always Active</div>
          </div>
          <div className="text-center text-tertiary mt-8 w-[80%]">
            Hi, I am nü(AI)de. I am here to help you determine the triage level of a patient based on the symptoms you describe during our chat.
          </div>
          <div className="text-tertiary mt-4">So let’s talk!</div>
        </div>`
    )
  })
}

function getCareModels() {
  return new Promise(function (resolve, reject) {
    resolve([
      {
        name: 'Triage and Continuous Health Monitoring',
        list: [
          {
            name: 'Medical',
            icon: 'ri-dossier-fill',
            path: MEDICAL_TRIAGE_PAGE_PATH,
            description: 'Determine level of medical care based on patient’s symptoms',
            enabled: true
          },
          {
            name: 'Behavioral',
            icon: 'ri-mental-health-fill',
            path: BEHAVIORAL_TRIAGE_PAGE_PATH,
            description: 'Insights into patient’s health based on symptoms',
            enabled: true
          },
          {
            name: 'Chat',
            icon: 'ri-message-3-fill',
            path: CHAT_PAGE_PATH,
            description: 'Find the level of medical care using interactive chat',
            enabled: true
          }
        ]
      },
      {
        name: 'Medical Documents and Processing Models',
        list: [
          {
            name: 'Summarization',
            icon: 'ri-file-text-fill',
            path: SUMMARIZATION_PAGE_PATH,
            description: 'Summarize large volume of medical data',
            enabled: true
          },
          {
            name: 'Q&A',
            icon: 'ri-survey-fill',
            path: CHARTS_QA_PAGE_PATH,
            description: 'Get answers from the historical medical charts knowledge base',
            enabled: true
          },
          {
            name: 'Medical Coding',
            icon: 'ri-swap-box-fill',
            path: MEDICAL_CODING_PAGE_PATH,
            description: 'Generate medical concepts from medical charts',
            enabled: true
          }
        ]
      },
      {
        name: "Compliance and Automation",
        list: [
          {
            name: 'Fraud & Waste Compliance',
            icon: 'ri-shield-check-fill',
            path: FRAUD_WASTE_COMPLIANCE_PAGE_PATH,
            description: 'System checks for ensuring compliance',
            enabled: false
          },
          {
            name: 'Ambient Dictation & Automation',
            icon: 'ri-mic-fill',
            path: AMBIENT_DICTATION_AUTOMATION_PAGE_PATH,
            description: 'Convert speech to text and automate documentation',
            enabled: false
          },
          {
            name: 'Pre-Auth & Assessment',
            icon: 'ri-draft-fill',
            path: PRE_AUTH_ASSESSMENT_PAGE_PATH,
            description: 'Evaluate and authorize medical procedures before they are performed',
            enabled: false
          }
        ]
      }
    ])
  })
}

function getDefaultProblemStatement() {
  return new Promise(function (resolve) {
    resolve({
      data: {
        defaultStatement:
          `Patient Document - History and Physical (H&P) Sample

      Patient Information:
      Name: Jane Doe   Age: 57   Gender: Female  Date of Birth: January 10, 1966
      Contact Number: (XXX) XXX-XXXX   Address: 456 Elm Street, Anytown, USA
      
      Chief Complaint: Abdominal pain and bloating
      
      History of Present Illness:
      Mrs. Doe presents to the clinic with a two-week history of persistent abdominal pain and bloating. She describes the pain as a dull ache located in the lower abdomen, which worsens after meals. She denies any associated nausea, vomiting, diarrhea, or changes in bowel habits. She has tried over-the-counter antacids with minimal relief. The pain is not alleviated by rest or changes in position. She reports no recent travel or known exposure to gastrointestinal illnesses. No weight loss or appetite changes have been noted.
      
      Past Medical History:
      
      - Hypothyroidism diagnosed in 2008, on levothyroxine replacement therapy
      - Cholecystectomy (gallbladder removal) in 2015 due to gallstones
      
      Medications:
      1. Levothyroxine - 75mcg, once daily for hypothyroidism`
      }
    })
  })
}

async function getUsers() {
  try {
    const response = await crmcAxiosInstance.get(
      USERS_URL,
      {
        params: {
          appSource: 'NuHealth',
          docType: 'user'
        }
      })
    return { data: response.data.results }
  } catch (e) {
    return e
  }
}


export {
  getIntroForHomePage,
  getCareModels,
  getDefaultProblemStatement,
  getIntroForChatPage,
  getUsers
}