import { crmcAxiosInstance } from '../lib/axios'
import { CUSTOM_SUMMARY_QA_V1_URL } from '../lib/api-urls'
import { v4 as uuidv4 } from 'uuid'

async function getMedicalConcepts({ content }) {
  try {
    return await crmcAxiosInstance.post(CUSTOM_SUMMARY_QA_V1_URL, {
      model_platform: "openai",
      model_temperature: 0,
      model_type: "qa",
      prompt_key: "NUHEALTH_MEDICAL_CODING",
      model_name: "gpt-4o",
      input_variables: {
        context: content,
        question: "",
        chat_history: [],
      },
      session_id: sessionStorage.getItem("sessionId"),
    })
  } catch (e) {
    return e
  }
}

export { getMedicalConcepts }