import { useState } from 'react';
import PageDescription from '../../components/PageDescription'
import BasicDetailsForm from './BasicDetailsForm';
import TriageResult from './TriageResult';
import SupplementaryQuestions from './SupplementaryQuestions';
import { STEP_STATUS_ACTIVE, STEP_STATUS_COMPLETED } from '../../lib/constants'
import Loader from '../../assets/gif/loader.svg'
import AuthenticatedLayout from '../../components/layouts/AuthenticatedLayout'

const initialState = {
  currentStep: 0,
  steps: {
    0: {
      title: 'Basic Details',
      description: 'All fields are mandatory',
      status: 'active',
      data: {
        mainComplaint: '',
        age: '',
        gender: '',
        skipAdditionalQuestions: false
      }
    },
    1: {
      title: 'Regulatory Questions',
      description: 'Some fields are mandatory',
      status: '',
      data: {
        fields: []
      }
    },
    2: {
      title: 'Triage Level',
      description: 'Results',
      status: '',
      data: {
        triageLevel: '',
        explanation: ''
      }
    }
  }
}
function MedicalTriagePage() {
  const [state, setState] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  function updateDataForStep({ data }) {
    const currentStep = state.steps[state.currentStep]
    const updatedSteps = {
      ...state.steps,
      [state.currentStep]: {
        ...currentStep,
        data: {
          ...currentStep.data,
          ...data
        }
      }
    }
    setState({ ...state, steps: updatedSteps })
  }

  function goToNextStep({ nextStepIndex, nextStepData }) {
    const { data = {}, ...remainingNextStepData } = nextStepData
    let currentStep = state.steps[state.currentStep]
    let nextStep = state.steps[nextStepIndex]
    const updatedSteps = {
      ...state.steps,
      [state.currentStep]: {
        ...currentStep,
        status: STEP_STATUS_COMPLETED
      },
      [nextStepIndex]: {
        ...nextStep,
        ...remainingNextStepData,
        status: nextStepIndex === 2 ? STEP_STATUS_COMPLETED : STEP_STATUS_ACTIVE,
        data: {
          ...nextStep.data,
          ...data
        }
      }
    }
    setState({ ...state, currentStep: nextStepIndex, steps: updatedSteps })
  }

  function goToPreviousStep({ previousStepIndex }) {
    let currentStep = state.steps[state.currentStep]
    let previousStep = state.steps[previousStepIndex]
    const updatedSteps = {
      ...state.steps,
      [previousStepIndex]: {
        ...previousStep,
        status: STEP_STATUS_ACTIVE
      },
      [state.currentStep]: {
        ...currentStep,
        status: ''
      }
    }
    setState({ ...state, currentStep: previousStepIndex, steps: updatedSteps })
  }

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col grow border-2 border-lightgrey">
        <PageDescription
          name="Medical Triage"
          description="Use this form to determine a patient’s triage level. Fields marked with a * are mandatory to submit the form"
        />
        <div className="flex flex-row bg-lightgrey gap-4 p-4">
          <div className="basis-1/4"></div>
          <div className="flex flex-row basis-2/4 px-6">
            {
              Object.keys(state.steps).map(function (stepId) {
                const step = state.steps[stepId]
                let borderColor
                let icon
                switch (step.status) {
                  case 'active':
                    borderColor = "border-t-primary"
                    icon = "ri-radio-button-line"
                    break
                  case 'completed':
                    borderColor = "border-t-primary"
                    icon = "ri-checkbox-circle-fill"
                    break
                  default:
                    borderColor = "border-t-light-grey"
                    icon = "ri-checkbox-blank-circle-line"
                    break
                }
                return (
                  <div key={`step-${stepId}`} className={`flex flex-row basis-1/3 pt-2 border-t-2 gap-1 ${borderColor}`}>
                    <div>
                      <i className={`${icon} text-base text-primary`}></i>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-sm font-bold">{step.title}</div>
                      <div className="text-sx font-normal text-tertiary">{step.description}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        {isLoading &&
          <div className="flex flex-row grow bg-lightgrey gap-4 p-4">
            <div className="basis-1/4"></div>
            <div className="flex flex-col basis-2/4 items-center">
              <img src={Loader} className='w-[50px]' alt="loading" />
            </div>
          </div>
        }
        {!isLoading && state.currentStep === 0 &&
          <div className="flex flex-row grow bg-lightgrey gap-4 p-4">
            <div className="basis-1/4"></div>
            <div className="flex flex-col basis-2/4">
              <BasicDetailsForm
                data={state.steps[state.currentStep].data}
                goToNextStep={goToNextStep}
                updateDataForStep={updateDataForStep}
                setIsLoading={setIsLoading}
              />
            </div>
          </div>
        }
        {!isLoading && state.currentStep === 1 &&
          <div className="flex flex-row grow bg-lightgrey gap-4 p-4">
            <div className="basis-1/4"></div>
            <div className="flex flex-col basis-2/4">
              <SupplementaryQuestions
                data={state.steps[state.currentStep]?.data}
                patientInfoObject={state.steps[0]}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
                updateDataForStep={updateDataForStep}
                setIsLoading={setIsLoading}
              />
            </div>
          </div>
        }
        {!isLoading && state.currentStep === 2 &&
          <div className="flex flex-row grow bg-lightgrey gap-4 p-4">
            <div className="basis-1/4"></div>
            <div className="flex flex-col basis-2/4">
              <TriageResult
                data={state.steps[state.currentStep]?.data}
                goToPreviousStep={goToPreviousStep}
                patientInfoObject={state.steps[0]}
              />
            </div>
          </div>
        }
      </div>
    </AuthenticatedLayout>
  )
}

export default MedicalTriagePage;