import { Link } from 'react-router-dom'

function CustomLink({ children, to, className = '' }) {
  return (
    <Link
      to={to}
      className={'text-secondary hover:text-secondary hover:no-underline active:text-secondary active:no-underline visited:text-secondary visited:no-underline hover:cursor ' + className}
    >
      {children}
    </Link>
  )

}

export default CustomLink;