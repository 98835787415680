function populateAuthDetails({ token, user }) {
  localStorage.setItem('accessToken', token)
  localStorage.setItem('userFullName', user.fullName)
  localStorage.setItem('userRole', user.userRole || 'USER')
}

function removeAuthDetails() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userFullName')
  localStorage.removeItem('userRole')
}

function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export { populateAuthDetails, removeAuthDetails, getAccessToken }