import CustomSwitch from '../CustomSwitch'
import CustomButton from '../CustomButton';

function OutputHeader({ header, description, enableDownload = false, enableClearing = false, handleClearing, handleDownload, allowDownload, enableSwitch, handleSwitchChange, switchValue }) {
  return (
    <div className="flex flex-row justify-between items-center p-4 border-b border-b-bg-grey">
      <div className="flex flex-col gap-1">
        <div className="font-bold">{header}</div>
        <div className="text-sm text-tertiary">{description}</div>
      </div>
      <div className="flex flex-row gap-2">
        {enableSwitch &&
          <CustomSwitch handleSwitchChange={handleSwitchChange} switchValue={switchValue} />
        }
        {enableDownload && allowDownload &&
          <CustomButton onClick={handleDownload} buttonType="tertiary-icon">
            <i className="ri-download-line" />
          </CustomButton>
        }
        {enableClearing &&
          <CustomButton onClick={handleClearing} buttonType="tertiary-icon">
            <i className="ri-format-clear" />
          </CustomButton>
        }
      </div>
    </div>
  )
}

export default OutputHeader;