import { useEffect, useState } from 'react';
import { exportToExcel } from "react-json-to-excel"
import AuthenticatedLayout from '../../components/layouts/AuthenticatedLayout'
import PageDescription from '../../components/PageDescription';
import ProblemStatement from '../../components/ProblemStatement'
import SearchableDropDown from '../../components/SearchableDropDown';
import OutputHeader from '../../components/OutputHeader';
import { getUsers } from '../../api/util'
import { getMedicalConcepts } from '../../api/medical-coding'
import Loader from '../../assets/gif/loader.svg'
import { MEDICAL_CODING_MAPPING } from '../../lib/constants'
import toast from '../../lib/toast'
import { extractJsonFromMarkdown } from '../../lib/helper';

const initialState = {
  statement: '',
  medicalConcepts: [],
  isLoading: false,
  users: [],
  selectedUserId: ''
}

function MedicalCodingPage() {
  const [state, setState] = useState(initialState)

  useEffect(function () {
    async function populateUsers() {
      const { data, error } = await getUsers();
      if (error) {
        toast({ message: error.message });
      } else {
        setState({ ...state, users: data || [] });
      }
    }
    populateUsers();
  }, [])

  function setProblemStatement(statement) {
    setState({ ...state, statement })
  }

  async function handleSubmit() {
    setState({ ...state, isLoading: true })
    const { data, error } = await getMedicalConcepts({ content: state.statement })
    if (error) {
      toast({ message: error.message })
      setState({ ...state, isLoading: false })
    } else {
      setState({ ...state, medicalConcepts: extractJsonFromMarkdown(data.text), isLoading: false })
    }
  }

  function handleDownload() {
    const formattedMedicalCodingData = state.medicalConcepts.map((record) => {
      const data = {}
      for (var key of Object.keys(record)) {
        data[MEDICAL_CODING_MAPPING[key]] = record[key]
      }
      return data
    })
    const date = new Date()
    exportToExcel(formattedMedicalCodingData, `medical-coding-${(date.getYear() + 1900)}_${(date.getMonth() + 1)}_${date.getDate()}`)
  }

  function handleClearing() {
    setState({ ...state, statement: '', medicalConcepts: [] })
  }


  function handleUserSelection(userId) {
    const user = state.users.filter(user => user.id === userId)
    if (user.length == 1) {
      setState({ ...state, selectedUserId: userId, statement: user[0].medicalStatement, medicalConcepts: [] });
    }
  }

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col grow border-2 border-lightgrey">
        <PageDescription name="Medical Coding" description="Translate complex medical information into universal medical codes" />
        <div className="flex flex-row justify-center items-center bg-lightgrey p-4">
          <SearchableDropDown
            id={`user-searchable-dropdown`}
            options={state.users && state.users.map((user) => {
              return {
                ...user,
                text: `${user.name} - ${user.age} - ${user.gender} - ${user.phoneNumber}`
              }
            })
            }
            open={false}
            disabled={state.users.length === 0}
            onChangeHandler={(newValue) => {
              if(newValue) {
                handleUserSelection(newValue.id)
              }
            }}
            placeholder={'Search for a member...'}
            otherStyleProps={{
              '& input': {
                padding: '0px !important'
              }
            }}
          />
        </div>

        <div className="flex flex-row grow bg-lightgrey gap-4 p-4 pt-0">
          <div className="flex flex-col basis-1/2 bg-white border-bg-grey rounded border">
            <ProblemStatement
              statement={state.statement}
              setProblemStatement={setProblemStatement}
              handleSubmit={handleSubmit}
              enableSubmit={true}
              handleClearing={handleClearing}
            />
          </div>
          <div className="flex flex-col basis-1/2 bg-white border-bg-grey rounded border">
            <div className="flex flex-col">
              <OutputHeader
                header="Medical Concepts"
                description="Generates a list of medical concepts relevant to the information provided"
                handleDownload={handleDownload}
                enableDownload={true}
                allowDownload={state.medicalConcepts.length !== 0}
              />
              <div className="flex flex-row p-4 max-h-[calc(100vh-310px)] h-[100%] max-w-[47vw] overflow-scroll lg:justify-center">
                {state.isLoading &&
                  <div className="flex flex-col grow justify-center items-center">
                    <img src={Loader} className='w-[50px]' alt="loading" />
                  </div>
                }
                {state.medicalConcepts.length > 0 &&
                  <table className="table-fixed table-collapsed border rounded ml-[2.5%]">
                    <thead>
                      <tr>
                        <th className="p-2 font-bold text-left border border-border-grey rounded-tl">Medical Concept</th>
                        <th className="p-2 font-bold text-left border border-border-grey">Sentiment</th>
                        <th className="p-2 font-bold text-left border border-border-grey">History</th>
                        <th className="p-2 font-bold text-left border border-border-grey">Experiencer</th>
                        <th className="p-2 font-bold text-left border border-border-grey">Snomedct Code</th>
                        <th className="p-2 font-bold text-left border border-border-grey rounded-tr">ICD10 Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.medicalConcepts.map(function (medicalConcept, index) {
                          return (
                            <tr key={`table-row-${index}`}>
                              <td className="p-2 text-table-data-grey border border-border-grey">{medicalConcept.medicalConcept}</td>
                              <td className="p-2 text-table-data-grey border border-border-grey">{medicalConcept.sentiment}</td>
                              <td className="p-2 text-table-data-grey border border-border-grey">{medicalConcept.history}</td>
                              <td className="p-2 text-table-data-grey border border-border-grey">{medicalConcept.experiencer}</td>
                              <td className="p-2 text-table-data-grey border border-border-grey">{medicalConcept.snomedctCode}</td>
                              <td className="p-2 text-table-data-grey border border-border-grey">{medicalConcept.icd10Code}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
                {/* <textarea type="text" value={}
                  className="border border-bg-grey border-rounded p-2 text-sm h-[100%] w-[100%] resize-none"
                  onChange={(e) => setProblemStatement(e.target.value)} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default MedicalCodingPage;