import { removeAuthDetails } from '../lib/local-storage'

function handleUnAuthError() {
  removeAuthDetails()
  window.location.reload()
}

function extractJsonFromMarkdown(markdownText) {
  // Regular expression to find the JSON content within Markdown
  const jsonRegex = /```json\n([\s\S]*?)```/;

  // Match the JSON content using the regular expression
  const match = markdownText.match(jsonRegex);

  // Extracted JSON string
  let jsonString = match && match[1];
  // replace all double quotes with backticks to handle multi line strings
  // jsonString = jsonString.replaceAll('"','`')
  jsonString = jsonString.replace(/\s+/g, ' ').trim();
  if (jsonString) {
    try {
      // Parse the JSON string into a JavaScript object
      const jsonObject = JSON.parse(jsonString);
      return jsonObject;
    } catch (error) {
      throw new Error("Error parsing JSON:", error);
    }
  } else {
    throw new Error("JSON not found in Markdown text.");
  }
}

function parseJsonString(text) {
  let jsonObject
  try {
    jsonObject = extractJsonFromMarkdown(text)
  } catch (error) {
    if(error.message == 'JSON not found in Markdown text.' && text.startsWith('{') && text.endsWith('}')) {
      try {
        jsonObject = JSON.parse(text)
      }catch(e) {
        throw new Error('Received Invalid response')
      }
    }
  }

  return jsonObject
}

export { handleUnAuthError, extractJsonFromMarkdown, parseJsonString }