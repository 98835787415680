import { useEffect, useState } from 'react'
import AuthenticatedLayout from '../../components/layouts/AuthenticatedLayout'
import PageDescription from '../../components/PageDescription'
import ProblemStatement from '../../components/ProblemStatement'
import SearchableDropDown from '../../components/SearchableDropDown';
import OutputHeader from '../../components/OutputHeader'
import { getUsers } from '../../api/util'
import { getCustomSummaryV1 } from '../../api/qa'
import Loader from '../../assets/gif/loader.svg'
import CustomButton from '../../components/CustomButton'
import toast from '../../lib/toast'
// import { v4 as uuidv4 } from "uuid";
import MarkdownRenderer from "react-markdown-renderer";

const initialState = {
  statement: '',
  chatHistory: [],
  isLoading: false,
  currentQuestion: '',
  selectedUserId: '',
  users: []
}
function ChartsQAPage() {
  const [state, setState] = useState(initialState)


  useEffect(function () {
    async function populateUsers() {
      const { data, error } = await getUsers();
      if (error) {
        toast({ message: error.message });
      } else {
        setState({ ...state, users: data || [] });
      }
    }
    populateUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function setProblemStatement(statement) {
    setState({ ...state, statement })
  }

  function handleChatClearing() {
    setState({ ...state, chatHistory: [] })
  }

  function handleClearing() {
    setState({ ...state, chatHistory: [], statement: '' })
  }

  function askQuestion() {
    if (!state.currentQuestion) {
      return
    }
    const question = state.currentQuestion
    const currentChatHistory = state.chatHistory
    setState({ ...state, isLoading: true, currentQuestion: '', chatHistory: [...currentChatHistory, { question }] })
    setTimeout(function () {
      scrollToBottom()
    }, 100)
    async function getAnswer() {
      const { data, error } = await getCustomSummaryV1({
        chatHistory: currentChatHistory,
        question,
        document: state.statement
      })

      if (error) {
        setState({ ...state, chatHistory: [...currentChatHistory, { question }], currentQuestion: '', isLoading: false })
        toast({ message: error.message })
      } else {
        const updatedChatHistory = [...currentChatHistory, { question, answer: data.text }]
        setState({ ...state, chatHistory: updatedChatHistory, currentQuestion: '', isLoading: false })
      }
      setTimeout(function () {
        scrollToBottom()
      }, 100)
    }
    getAnswer()
  }

  function handleCurrentQuestionChange(currentQuestion) {
    setState({ ...state, currentQuestion })
  }


  function getAIMessage(message, index) {
    return (
      <div key={"answer-" + index} className="flex flex-row">
        <div className="flex flex-row basis-2/3 justify-start items-center gap-3">
          <div className="px-2 py-1 rounded-lg bg-bg-grey border border-border-grey">
            <i className="ri-robot-2-fill text-primary text-3xl"></i>
          </div>
          <div className="flex text-tertiary p-4 rounded-r-xl rounded-bl-xl bg-bg-grey border border-border-grey items-center">
            <MarkdownRenderer markdown={message} />
          </div>
        </div>
      </div>
    )
  }

  function getHumanMessage(message, index) {
    return (
      <div key={"question-" + index} className="flex flex-row">
        <div className="basis-1/3"></div>
        <div className="flex flex-row basis-2/3 justify-end items-center gap-3">
          <div className="flex text-tertiary py-3 px-5 rounded-l-xl rounded-tr-xl bg-primary text-white items-center text-sm">
            {message}
          </div>
        </div>
      </div>
    )
  }

  function getChatComponent() {
    return state.chatHistory.map(function (record, index) {
      let humanMessage, aiMessage
      if (record.question) {
        humanMessage = getHumanMessage(record.question, index)
      }
      if (record.answer) {
        aiMessage = getAIMessage(record.answer, index)
      }
      return (
        <>
          {humanMessage}
          {aiMessage}
        </>
      )
    })
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' && !state.isLoading) {
      askQuestion()
    }
  }

  function scrollToBottom() {
    const element = document.getElementById("scrollable-parent");
    element.scrollTop = element.scrollHeight;
  }

  function handleUserSelection(userId) {
    const user = state.users.filter(user => user.id === userId)
    if (user.length == 1) {
      setState({ ...state, selectedUserId: userId, statement: user[0].medicalStatement, chatHistory: [] });
    }
  }

  const chatComponent = getChatComponent()

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col grow border-2 border-lightgrey">
        <PageDescription
          name="Q&A"
          description="Use this form to ask free-form questions related to a patient's chart"
        />
        <div className="flex flex-row justify-center items-center bg-lightgrey p-4">
          <SearchableDropDown
            id={`user-searchable-dropdown`}
            options={state.users && state.users.map((user) => {
              return {
                ...user,
                text: `${user.name} - ${user.age} - ${user.gender} - ${user.phoneNumber}`
              }
            })
            }
            open={false}
            disabled={state.users.length === 0}
            onChangeHandler={(newValue) => {
              if(newValue) {
                handleUserSelection(newValue.id)
              }
            }}
            placeholder={'Search for a member...'}
            otherStyleProps={{
              '& input': {
                padding: '0px !important'
              }
            }}
          />
        </div>
        <div className="flex flex-row grow bg-lightgrey gap-4 p-4 pt-0">
          <div className="basis-1/2 bg-white border-bg-grey rounded border">
            <ProblemStatement
              setProblemStatement={setProblemStatement}
              statement={state.statement}
              enableModelSettings={false}
              enableSubmit={false}
              handleClearing={handleClearing}
            />
          </div>
          <div className="flex flex-col basis-1/2 bg-white border-bg-grey rounded border">
            <OutputHeader
              header="Q&A"
              description="Chat with our AI assistant and find answers related to the chart"
              handleClearing={handleChatClearing}
              enableClearing={true}
            />
            <div className="flex flex-col grow gap-3 p-4 max-h-[calc(100vh-330px)] h-[100%] w-[100%]">
              <div className="flex flex-col bg-bg-grey max-h-[calc(100vh-350px)] h-[100%] w-[100%] p-4 gap-3 overflow-scroll" id="scrollable-parent">
                {chatComponent}
                {state.isLoading &&
                  <div className="flex flex-col justify-center items-center">
                    <img src={Loader} className='w-[50px]' alt="loading" />
                  </div>
                }
              </div>
              <div className="flex flex-row items-center p-4 bg-white gap-2 rounded-md border-bg-grey border">
                <div className="flex grow">
                  <input className="w-[100%] text-sm text-tertiary rounded py-2 px-3 border border-border-grey focus:border-border-grey"
                    type="text" placeholder="Type a message..." value={state.currentQuestion} onChange={(e) => handleCurrentQuestionChange(e.target.value)}
                    onKeyDown={handleKeyDown} disabled={state.isLoading}
                  />
                </div>
                <i className="ri-mic-line text-xl text-tertiary"></i>
                <CustomButton additionalStyle="px-2 py-1" rounded="rounded-full" onClick={askQuestion} disabled={(state.isLoading || !state.currentQuestion)}>
                  <i className="ri-send-plane-fill text-white text-xl"></i>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default ChartsQAPage;