import { useEffect, useState } from "react";
import parse from "html-react-parser";
import AuthenticatedLayout from "../../components/layouts/AuthenticatedLayout";
import PageDescription from "../../components/PageDescription";
import ProblemStatement from "../../components/ProblemStatement";
import SearchableDropDown from "../../components/SearchableDropDown";
import OutputHeader from "../../components/OutputHeader";
import { getUsers } from "../../api/util";
import { getCustomSummaryV1 } from "../../api/summarization";
import {
  PRESET_API_KEY,
  TEST_KEY_OPTION_VALUE_MODEL_SETTINGS,
  LLM_MODEL_VALUE_V2,
} from "../../lib/constants";
import Loader from "../../assets/gif/loader.svg";
import toast from "../../lib/toast";
import "./index.css";
import { extractJsonFromMarkdown } from "../../lib/helper";

const initialState = {
  statement: "",
  summary: {},
  isLoading: false,
  apiKey: PRESET_API_KEY,
  defaultApiKey: TEST_KEY_OPTION_VALUE_MODEL_SETTINGS,
  model: LLM_MODEL_VALUE_V2,
  temperature: "0",
  switchValue: 0,
  selectedUserId: '',
  users: []
};
function SummarizationPage() {
  const [state, setState] = useState(initialState);

  useEffect(function () {
    async function populateUsers() {
      const { data, error } = await getUsers();
      if (error) {
        toast({ message: error.message });
      } else {
        setState({ ...state, users: data || [] });
      }
    }
    populateUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setProblemStatement(statement) {
    setState({ ...state, statement });
  }

  function handleClearing() {
    setState({ ...state, statement: '', summary: {} });
  }

  async function handleSubmit() {
    setState({ ...state, isLoading: true, summary: {} });
    const { data, error } = await getCustomSummaryV1({
      content: state.statement,
      apiKey: state.apiKey ? state.apiKey : state.defaultApiKey,
      model: state.model,
      temperature: state.temperature,
      defaultApiKey: state.defaultApiKey,
    });

    if (error) {
      toast({ message: error.message });
      setState({ ...state, isLoading: false });
    } else {
      try {
        const summary = extractJsonFromMarkdown(data.text)
        setState({
          ...state,
          summary: summary,
          isLoading: false,
        });
      } catch (e) {
        toast({ message: 'Error parsing summary. Please try again later' })
        setState({
          ...state,
          isLoading: false
        })
      }

    }
  }

  function setModelSettings(data) {
    setState({ ...state, ...data });
  }

  function handleSwitchChange(e, value) {
    setState({ ...state, switchValue: value });
  }

  function handleUserSelection(userId) {
    const user = state.users.filter(user => user.id === userId)
    if (user.length == 1) {
      setState({ ...state, selectedUserId: userId, statement: user[0].medicalStatement, summary: {} });
    }
  }

  function processSummary() {
    if (state.switchValue === 1) {
      return parse(state?.summary?.detailed_summary || "");
    } else {
      return (
        <ul className="summary-ul ml-[15px]">
          {state?.summary?.bulleted_summary?.map((value, index) => {
            if (value.trimStart().startsWith("-")) {
              const parts = value.trimStart().split(":");
              return (
                <li key={`summary-point-${index}`} className="ml-3">
                  {parts.length > 1 ? <strong>{parts[0]}</strong> : parts[0]}
                  {parts.length > 1 ? `: ${parts.slice(1).join(":")}` : ""}
                </li>
              );
            }
            const parts = value.split(":");
            return (
              <li key={`summary-point-${index}`} className="list-disc">
                {parts.length > 1 ? <strong>{parts[0]}</strong> : parts[0]}
                {parts.length > 1 ? `: ${parts.slice(1).join(":")}` : ""}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const summaryToBeRendered = processSummary();

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col grow border-2 border-lightgrey">
        <PageDescription
          name="Summarization"
          description="Use this form to summarize large volume patient charts"
        />
        <div className="flex flex-row justify-center items-center bg-lightgrey p-4">
          <SearchableDropDown
            id={`user-searchable-dropdown`}
            options={state.users && state.users.map((user) => {
              return {
                ...user,
                text: `${user.name} - ${user.age} - ${user.gender} - ${user.phoneNumber}`
              }
            })
            }
            open={false}
            disabled={state.users.length === 0}
            onChangeHandler={(newValue) => {
              if(newValue) {
                handleUserSelection(newValue.id)
              }
            }}
            placeholder={'Search for a member...'}
            otherStyleProps={{
              '& input': {
                padding: '0px !important'
              }
            }}
          />
        </div>

        <div className="flex flex-row grow bg-lightgrey gap-4 p-4 pt-0">
          <div className="basis-1/2 bg-white border-bg-grey rounded border">
            <ProblemStatement
              setProblemStatement={setProblemStatement}
              statement={state.statement}
              enableModelSettings={true}
              enableSubmit={true}
              handleSubmit={handleSubmit}
              setModelSettings={setModelSettings}
              modelSettings={{
                model: state.model,
                apiKey: state.apiKey,
                defaultApiKey: state.defaultApiKey,
                temperature: state.temperature,
              }}
              handleClearing={handleClearing}
            />
          </div>
          <div className="basis-1/2 bg-white border-bg-grey rounded border">
            <div className="flex flex-col h-[100%]">
              <OutputHeader
                header="Output"
                description="View the summarized version here"
                enableSwitch={true}
                handleSwitchChange={handleSwitchChange}
                switchValue={state.switchValue}
              />
              <div className="flex flex-col p-4 grow">
                <div className="flex h-[100%] w-[100%] bg-lightgrey border-bg-grey rounded border p-2">
                  {state.isLoading && (
                    <div className="flex flex-col grow justify-center items-center">
                      <img src={Loader} className="w-[50px]" alt="loading" />
                    </div>
                  )}
                  {summaryToBeRendered}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  );
}

export default SummarizationPage;
