import { useState } from "react"
import CustomButton from '../../../components/CustomButton'
import { getTriageQuestionsV1 } from '../../../api/triage'
import toast from "../../../lib/toast"

const initialState = {
  mainComplaint: '',
  age: '',
  gender: '',
  skipAdditionalQuestions: false
}
function BasicDetailsForm({ data, goToNextStep, updateDataForStep, setIsLoading }) {
  const [state, setState] = useState({ ...initialState, ...data })

  function handleChange(field, value) {
    setState({ ...state, [field]: value })
    updateDataForStep({ data: { [field]: value } })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    const { data, error } = await getTriageQuestionsV1({ mainComplaint: state.mainComplaint, age: state.age, gender: state.gender })
    if (error) {
      toast({ message: error.message })
    } else {
      goToNextStep({
        nextStepIndex: 1,
        nextStepData: {
          data: {
            fields: data
          }
        }
      })
    }
    setIsLoading(false)
  }

  return (
    <div className="flex flex-col bg-white rounded border border-near-white">
      <div className="flex flex-col p-4 gap-2">
        <div className="font-bold">Basic Details</div>
        <div className="text-xs text-tertiary">Use this section to specify details of the patient and primary symptoms</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col p-4 gap-4 border-t border-t-near-white">
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium">Enter main complaint *</label>
            <input
              className="border border-grey rounded py-1 px-2"
              placeholder="Chest Pain, Difficulty Breathing"
              onChange={(e) => handleChange('mainComplaint', e.target.value)}
              value={state.mainComplaint}
              required
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-body-disabled">About the patient</label>
            <div className="flex flex-row gap-2">
              <div className="flex flex-col basis-1/2 gap-1">
                <label className="text-sm text-tertiary">Gender *</label>
                <select
                  className="border border-grey rounded py-1 px-2"
                  onChange={(e) => handleChange('gender', e.target.value)}
                  value={state.gender}
                  required
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="flex flex-col basis-1/2 gap-1">
                <label className="text-sm text-tertiary">Age *</label>
                <input
                  type="number"
                  min={1}
                  max={100}
                  className="border border-grey rounded py-1 px-2"
                  onChange={(e) => handleChange('age', e.target.value)}
                  value={state.age}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end">
            <CustomButton type="submit" additionalStyle="px-4 py-2">Next</CustomButton>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BasicDetailsForm