import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setTokenToLocalStorage } from "../auth";
import { HOME_URL, LS_TOKEN_KEY } from "../config";
const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Parse the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const session_state = urlParams.get("session_state");

    const getToken = async () => {
      if (code && session_state) {
        await setTokenToLocalStorage(code,session_state);
        const oauthCache = localStorage.getItem(LS_TOKEN_KEY);

        if (!oauthCache) {
          // Handle the case when the token is not available
          console.error('oauthCache not found in LocalStorage');
          navigate("/");
          return;
        }
        navigate(HOME_URL);
      }
    };
    getToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* You can add a loading spinner or any other UI elements here if needed */}
      <p>Handling login callback...</p>
    </div>
  );
};

export default LoginCallback;
