import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { handleLogout } from '../auth';

const Logout = () => {
  
  const navigate = useNavigate();
  useEffect(() => {

    localStorage.clear()

    navigate("/");
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <h2>Logout</h2>
      <button onClick={handleLogout}>Logout with Azure AD</button>
    </div>
  );
};

export default Logout;
