import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LOGIN_PATH, LS_TOKEN_KEY } from "../config";
import { isTokenValid } from "../auth";

const ProtectedRoute = () => {
  // Check if the user is authenticated
  const isAuthenticated = () => {
    const token = localStorage.getItem(LS_TOKEN_KEY);

    if (token) {
      return isTokenValid(token)
    }

    // No token found
    return false;
  };

  return !isAuthenticated() ? <Navigate to={LOGIN_PATH} /> : <Outlet />;
};

export default ProtectedRoute;
