import parse from "html-react-parser";
import CustomButton from "../../../components/CustomButton";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function TriageResult({ data = {}, goToPreviousStep }) {
  function goBack() {
    goToPreviousStep({ previousStepIndex: 1 });
  }

  const backgroundColorStyle = "bg-[" + (data.bgColor || "white") + "]";
  const fontColorStyle = "text-[" + (data.fontColor || "black") + "]";
  return (
    <div className="flex flex-col grow bg-white rounded border border-near-white">
      <div className="flex flex-row">
        <div className="flex flex-col grow px-5 py-5 gap-1">
          <div className="font-bold">Triage Level</div>
          <div className="text-xs text-tertiary">
            This section summarizes the triage level and it’s reasoning
          </div>
        </div>
        <div className={`flex flex-row p-5`}>
          <div
            className={`flex flex-row grow rounded-md border border-lightgrey ${backgroundColorStyle} ${fontColorStyle}`}
          >
            <div className="flex flex-col items-center p-3 justify-center w-[80px]">
              <CircularProgressbar
                value={`${data.score || 0}`}
                text={`${data.score || 0}`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textSize: "24px",
                  pathColor: `#017A49`,
                  textColor: `#017A49`,
                  trailColor: "white",
                })}
              />
            </div>
            <div className="flex flex-col grow text-base px-3 font-black items-center justify-center uppercase ">
              {data.triageLevel}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-h-[calc(100vh-300px)] grow p-4 bg-white gap-4">
        <div className="flex flex-col grow bg-grey overflow-scroll p-4 gap-2">
          <div className="text-heading-secondary font-bold">
            Suggested Treatment
          </div>
          <div className="text-tertiary">
            {parse(data.suggestedTreatment || "")}
          </div>
        </div>
        <div className="flex flex-col grow bg-grey overflow-scroll p-4 gap-2">
          <div className="text-heading-secondary font-bold">
            Suggested Medications
          </div>
          <div className="text-tertiary">
            {parse(data.suggestedMedications || "")}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end p-4">
        <CustomButton
          onClick={goBack}
          buttonType="secondary"
          additionalStyle="px-4 py-2"
        >
          Back
        </CustomButton>
      </div>
    </div>
  );
}

export default TriageResult;
