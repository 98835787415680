import React from "react";
import Header from "../header/Header";
import { ToastContainer } from "react-toastify";
import { Container, CssBaseline } from "@mui/material";

const AuthenticatedLayout = ({ children }) => {
  return (
    <div className="flex flex-col h-[100vh] overflow-scroll p-0">
      <Header />
      <div className="flex grow max-h[calc(100vh-82px)] overflow-scroll">
        {children}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AuthenticatedLayout;
