import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AUTH_BACKEND_URL, REDIRECT_URI, LOGOUT_REDIRECT_URI, LS_TOKEN_KEY, HOME_URL, LS_LOCAL_LOGIN_KEY } from "./config";
import { jwtDecode } from "jwt-decode";

const api = axios.create({
  baseURL: AUTH_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleLogin = async () => {
  try {
    const response = await axios.get(
      `${AUTH_BACKEND_URL}/login?redirect_uri=${REDIRECT_URI}`
    );

    // Check if the response contains a URL
    const redirectUrl = response.data.url;
    if (redirectUrl) {
      window.location.href = redirectUrl; // Redirect to Azure AD login
    } else {
      console.error("Invalid response from server");
    }
  } catch (error) {
    console.error("Error during login:", error);
  }
};

const handleLocalLogin = async ({ username, password }) => {
  try {
    const response = await axios.post(`${AUTH_BACKEND_URL}/local-login`, {
      username,
      password,
    });
    if (response.data.access_token) {
      localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(response.data));
      localStorage.setItem(LS_LOCAL_LOGIN_KEY, "true");
    }
    window.location.href = HOME_URL
    return { error: '' }
  } catch (error) {
    console.error("Error during login:", error);
    return { error };
  }
};

const handleLogout = async () => {
  try {
    const isLocalLogin = localStorage.getItem("LOCAL") === "true";

    if (isLocalLogin) {
      // Remove the local login indicator from local storage
      localStorage.removeItem("LOCAL");

      // Redirect to the "/logout" path
      window.location.href = "/logout";
    } 

    const response = await axios.get(
      `${AUTH_BACKEND_URL}/logout?redirect_uri=${LOGOUT_REDIRECT_URI}`
    );

    // Check if the response contains a URL
    const redirectUrl = response.data.url;
    if (redirectUrl) {
      window.location.href = redirectUrl; // Redirect to Azure AD Logout
    } else {
      console.error("Invalid response from server");
    }
  } catch (error) {
    console.error("Error during Logout:", error);
  }
};

const handleSilentRefreshToken = async () => {
  const oauthCache = localStorage.getItem(LS_TOKEN_KEY);

  if (!oauthCache) {
    // Handle the case when the token is not available
    console.error("oauthCache not found in LocalStorage");
    return;
  }

  const refreshToken = JSON.parse(oauthCache).refresh_token;

  const response = await axios.post(
    `${AUTH_BACKEND_URL}/token/refresh?redirect_uri=${REDIRECT_URI}`,
    `refresh_token=${refreshToken}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  if (response.data["Refreshed Token Data"]["token_type"] === "Bearer") {
    localStorage.setItem(
      LS_TOKEN_KEY,
      JSON.stringify(response.data["Refreshed Token Data"])
    );
  }
};

const setTokenToLocalStorage = async (code, session_state) => {
  if (code && session_state) {
    const response = await axios.get(
      `${AUTH_BACKEND_URL}/login/callback?code=${code}&session_state=${uuidv4()}&redirect_uri=${REDIRECT_URI}`
    );
    if (response.data["Token Data"]["token_type"] === "Bearer") {
      localStorage.setItem(
        LS_TOKEN_KEY,
        JSON.stringify(response.data["Token Data"])
      );
    }
  }
};

const authenticatedRequest = async ({ method, url, data, params }) => {
  try {
    // Retrieve the Bearer token from LocalStorage
    const oauthCache = localStorage.getItem(LS_TOKEN_KEY);

    if (!oauthCache) {
      // Handle the case when the token is not available
      console.error("oauthCache not found in LocalStorage");
      return;
    }

    const token = JSON.parse(oauthCache).access_token;

    // TODO: IF TOKEN EXPIRED REFRESH TOKEN - Implement refresh token mechanism later

    const response = await api.request({
      method,
      url,
      data,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      // Handle the successful response
      return response.data;
    } else {
      // Handle the case when the server returns an error
      console.error(
        `Error ${method} request:`,
        response.status,
        response.statusText
      );
    }
  } catch (error) {
    // Handle network errors or other exceptions
    console.error(`Error ${method} request:`, error.message);
  }
};
const isTokenValid = (token) => {
  try {
    // Verify the token's validity
    const decodedToken = jwtDecode(JSON.parse(token)?.access_token);
    // console.log(decodedToken);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (decodedToken.exp > currentTimestamp) {
      return true;
    }
    return false;
  } catch (error) {
    // Token is invalid or expired
    console.log(error);
    return false;
  }
}

export {
  authenticatedRequest,
  handleLogin,
  handleLocalLogin,
  handleLogout,
  handleSilentRefreshToken,
  setTokenToLocalStorage,
  isTokenValid
};
