import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import routes from "./routes";
import ProtectedRoute from "./auth/components/ProtectedRoute";
import { theme } from "./theme/theme";
import { ThemeProvider } from "@mui/material";
const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {routes.map((route) =>
              route?.isPrivate ? (
                <Route
                  exact
                  path={route.path}
                  element={<ProtectedRoute />}
                  key={route.path}
                >
                  <Route exact path={route.path} element={route.element} />
                </Route>
              ) : (
                <Route
                  exact
                  path={route.path}
                  element={route.element}
                  key={route.path}
                />
              )
            )}
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
};
export default App;
