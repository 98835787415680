const LOGIN_PAGE_PATH = "/login"
const SIGNUP_PAGE_PATH = "/sign-up"
const HOME_PAGE_PATH = "/home"
const LOGOUT_PAGE_PATH = "/logout"
const MEDICAL_TRIAGE_PAGE_PATH = "/care-models/medical-triage"
const BEHAVIORAL_TRIAGE_PAGE_PATH = "/care-models/behavioral-triage"
const CHAT_PAGE_PATH = "/care-models/chat"
const SUMMARIZATION_PAGE_PATH = "/care-models/summarization"
const CHARTS_QA_PAGE_PATH = "/care-models/charts-qa"
const MEDICAL_CODING_PAGE_PATH = "/care-models/medical-coding"
const FRAUD_WASTE_COMPLIANCE_PAGE_PATH = "/care-models/charts-qa"
const AMBIENT_DICTATION_AUTOMATION_PAGE_PATH = "/care-models/medical-coding"
const PRE_AUTH_ASSESSMENT_PAGE_PATH = "/model-settings"
const MODEL_SETTING_PAGE_PATH = "/model-settings"
const BASE_PATH = "/"

export {
  LOGIN_PAGE_PATH,
  SIGNUP_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGOUT_PAGE_PATH,
  MEDICAL_TRIAGE_PAGE_PATH,
  BEHAVIORAL_TRIAGE_PAGE_PATH,
  CHAT_PAGE_PATH,
  SUMMARIZATION_PAGE_PATH,
  CHARTS_QA_PAGE_PATH,
  MEDICAL_CODING_PAGE_PATH,
  FRAUD_WASTE_COMPLIANCE_PAGE_PATH,
  AMBIENT_DICTATION_AUTOMATION_PAGE_PATH,
  PRE_AUTH_ASSESSMENT_PAGE_PATH,
  MODEL_SETTING_PAGE_PATH,
  BASE_PATH
}