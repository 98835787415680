import TextFileUpload from "../TextFileUpload"
import { useState } from "react"
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { PRESET_API_KEY, TEST_KEY_OPTION_VALUE_MODEL_SETTINGS, SELF_KEY_OPTION_VALUE_MODEL_SETTINGS, LLM_MODEL_VALUE_V1, LLM_MODEL_VALUE_V2 } from '../../lib/constants'
import Slider from '../Slider'
import CustomButton from "../CustomButton";

const initialState = {
  open: false,
  anchor: null,
  modelSettings: {}
}

function ProblemStatement({
  statement,
  handleSubmit,
  setProblemStatement,
  enableUpload = true,
  enableClearingOption = true,
  enableSubmit = false,
  enableModelSettings = false,
  modelSettings = {},
  setModelSettings,
  handleClearing
}) {
  const [state, setState] = useState({ ...initialState, modelSettings })

  function openCloseSettingsModel(e) {
    if (state.open) {
      setState({ ...state, open: false, anchor: null })
    } else {
      setState({ ...state, open: true, anchor: e.target })
    }
  }

  function handleKeyChange(value) {
    setState({
      ...state, modelSettings: {
        ...state.modelSettings,
        defaultApiKey: value,
        apiKey: value === 'Use Test Key' ? PRESET_API_KEY : ''
      }
    })
  }

  function handleApiKeyChange(value) {
    setState({
      ...state, modelSettings: {
        ...state.modelSettings,
        apiKey: value
      }
    })
  }

  function handleModelChange(value) {
    setState({
      ...state, modelSettings: {
        ...state.modelSettings,
        model: value
      }
    })
  }

  function handleTemperatureChange(value) {
    setState({
      ...state, modelSettings: {
        ...state.modelSettings,
        temperature: value
      }
    })
  }

  function applyChanges() {
    setModelSettings({ ...state.modelSettings })
    setState({ ...state, open: false })
  }

  function clearChanges() {
    setState({ ...state, modelSettings, open: false })
  }

  return (
    <div className="flex flex-col h-[100%]">
      <div className="flex flex-row justify-between items-center p-4 border-b border-b-bg-grey">
        <div className="flex flex-col gap-1">
          <div className="font-bold">Member Charts</div>
          <div className="text-sm text-tertiary">Select a member or simply upload a file</div>
        </div>
        <div className="flex flex-row gap-2">
          {enableUpload &&
            <TextFileUpload setProblemStatement={setProblemStatement} />
          }
          {enableModelSettings &&
            <CustomButton onClick={openCloseSettingsModel}  buttonType="tertiary-icon">
              <i className="ri-equalizer-line" />
            </CustomButton>
          }
          {enableClearingOption &&
            <CustomButton onClick={handleClearing}  buttonType={statement == '' ? 'disabled-secondary' : 'tertiary-icon'}>
              <i className="ri-format-clear" />
            </CustomButton>
          }
          {enableSubmit &&
            <CustomButton onClick={handleSubmit} buttonType={statement == '' ? 'disabled' : 'secondary-icon'}>
              <i className="ri-play-circle-line" />
            </CustomButton>
          }
          <BasePopup id={state.open ? 'model-settings' : ''} open={state.open} anchor={state.anchor} placement="bottom">
            <div className={`flex flex-col p-3 gap-8 w-[500px] bg-white border rounded-md 
                              border-border-grey shadow-[0px_4px_4px_0px_rgba(0, 0, 0, 0.25)]`}>
              <div className="font-medium text-[22px]">Settings</div>
              <div className="flex flex-row">
                <div className="flex flex-col basis-1/2">
                  <div className="text-tertiary font-medium">Model *</div>
                  <select
                    className="p-1 border border-bg-grey rounded-md"
                    value={state.modelSettings.model}
                    onChange={(e) => handleModelChange(e.target.value)}
                  >
                    <option value={LLM_MODEL_VALUE_V2}>NU-LLM-V2</option>
                    <option value={LLM_MODEL_VALUE_V1}>NU-LLM-V1</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-tertiary font-medium">Open AI Key *</div>
                <div className="flex flex-row gap-5">
                  <div className="flex flex-col basis-1/2 gap-1">
                    <select value={state.modelSettings.defaultApiKey} onChange={(e) => handleKeyChange(e.target.value)}
                      className="px-2 py-1 border border-bg-grey rounded-md">
                      <option value={SELF_KEY_OPTION_VALUE_MODEL_SETTINGS}>Self</option>
                      <option value={TEST_KEY_OPTION_VALUE_MODEL_SETTINGS}>Use Test Key</option>
                    </select>
                  </div>
                  <div className="flex flex-col basis-1/2  gap-1">
                    <input
                      type="text" value={state.modelSettings.apiKey}
                      onChange={(e) => handleApiKeyChange(e.target.value)}
                      className="border border-bg-grey rounded-md px-2 py-0.5"
                      disabled={state.modelSettings.defaultApiKey === TEST_KEY_OPTION_VALUE_MODEL_SETTINGS}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="basis-2/3">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row justify-between">
                      <div className="text-tertiary font-medium">Temperature</div>
                      <div className="text-tertiary font-medium">{state.modelSettings.temperature}</div>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-tertiary">0</span>
                      <div className="flex flex-col grow mx-1 px-2 justify-center">
                        <Slider
                          progress
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleTemperatureChange}
                          className="custom-slider"
                          handleStyle={{
                            borderRadius: 10,
                            color: '#fff',
                            width: 15,
                            height: 15
                          }}
                          tooltip={false}
                        />
                      </div>
                      <span className="text-tertiary">1</span>
                    </div>

                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-end gap-4">
                <CustomButton additionalStyle="px-4 py-2" onClick={clearChanges} buttonType="secondary">
                  Cancel
                </CustomButton>
                <CustomButton additionalStyle="px-4 py-2" onClick={applyChanges}>
                  Apply
                </CustomButton>
              </div>
            </div>
          </BasePopup>
        </div>
      </div>
      <div className="flex grow p-4">
        <textarea type="text" value={statement}
          className="border border-bg-grey rounded p-2 text-sm h-[100%] w-[100%] resize-none"
          onChange={(e) => setProblemStatement(e.target.value)} />
      </div>
    </div>
  )
}
export default ProblemStatement