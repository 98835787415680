import { crmcAxiosInstance } from '../lib/axios'
import {
  BEHAVIORAL_TRIAGE_QUESTIONS_V1_URL,
  BEHAVIORAL_TRIAGE_LEVEL_V1_URL
} from '../lib/api-urls'
import { parseJsonString } from '../lib/helper'

async function getBTriageQuestionsV1({ disease, age, gender }) {
  try {
    const response = await crmcAxiosInstance.post(BEHAVIORAL_TRIAGE_QUESTIONS_V1_URL, {
      model_platform: "openai",
      model_temperature: 0,
      prompt_key: "NUHEALTH_BEHAVIORAL_QUESTIONS",
      model_name: "gpt-4o",
      input_variables: {
        context: `Complaint: ${disease}\nAge: ${age}\nGender: ${gender}`,
      },
      session_id: sessionStorage.getItem("sessionId"),
    })

    return {
      data: parseJsonString(response.data.text)
    }
  } catch (e) {
    return e
  }
}

async function getBTriageLevelV1({ patientInfo }) {
  try {
    const response = await crmcAxiosInstance.post(BEHAVIORAL_TRIAGE_LEVEL_V1_URL, {
      model_platform: "openai",
      model_temperature: 0,
      prompt_key: "NUHEALTH_BEHAVIORAL_TRIAGE_LEVEL",
      model_name: "gpt-4o",
      input_variables: {
        context: patientInfo
      },
      session_id: sessionStorage.getItem("sessionId"),
    })

    return {
      data: parseJsonString(response.data.text)
    }
  } catch (e) {
    return e
  }
}

function getDiseasesAndCategories() {
  return new Promise(function (resolve, reject) {
    resolve({
      data: [
        {
          "category": "Mental Health Conditions",
          "diseases": [
            "PTSD (Post-Traumatic Stress Disorder)",
            "Depression",
            "Anxiety Disorders",
            "Substance Abuse"
          ]
        },
        {
          "category": "Physical Health Conditions",
          "diseases": [
            "Chronic Pain",
            "Sleep Disorders",
            "Traumatic Brain Injury (TBI)"
          ]
        },
        {
          "category": "Behavioral Indicators",
          "diseases": [
            "Risk of Self-Harm or Suicide",
            "Anger and Irritability",
            "Social Withdrawal"
          ]
        },
        {
          "category": "Daily Functioning",
          "diseases": [
            "Difficulty in Daily Activities",
            "Employment Challenges",
            "Relationship Struggles"
          ]
        },
        {
          "category": "Cognitive Functioning",
          "diseases": [
            "Memory Issues",
            "Concentration Problems",
            "Decision-Making Difficulties"
          ]
        },
        {
          "category": "Support Systems",
          "diseases": [
            "Availability of Social Support",
            "Family and Community Engagement",
            "Access to Healthcare Services"
          ]
        },
        {
          "category": "Life Events and Stressors",
          "diseases": [
            "Recent Losses or Traumatic Events",
            "Financial Difficulties",
            "Housing Stability"
          ]
        },
        {
          "category": "Military-Specific Factors",
          "diseases": [
            "Transition to Civilian Life",
            "Combat Experience",
            "Military Sexual Trauma (MST)"
          ]
        }
      ]
    })
  })
}

export {
  getDiseasesAndCategories,
  getBTriageQuestionsV1,
  getBTriageLevelV1
}