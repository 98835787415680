import { useRef } from 'react'
import CustomButton from '../CustomButton';

function TextFileUpload({ setProblemStatement }) {
  const fileRef = useRef(null)

  function handleButtonClick() {
    fileRef.current.click()
  }

  function handleChange(e) {
    const fileUploaded = e.target.files[0];

    var reader = new FileReader();

    reader.onload = function (e) {
      var content = reader.result;

      // Here the content has been read successfuly
      setProblemStatement(content)
    }
    if (fileUploaded) {
      reader.readAsText(fileUploaded)
    }
  }

  return (
    <CustomButton onClick={handleButtonClick}  buttonType="tertiary-icon">
      <i className="ri-upload-line"/>
      <input type="file" name="file" ref={fileRef} onChange={handleChange} className="hidden" accept=".txt" />
    </CustomButton>
  )
}

export default TextFileUpload