import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#017A49',
      light: '#ECFCF4',
      dark: '#01623A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0090B8',
    },
  },
  typography: {
    fontFamily: [
      'DM Sans'
    ].join(',')
  }
});

export {
  theme
}