import { HOME_PAGE_PATH } from '../../lib/paths'
import CustomLink from '../CustomLink'

function PageDescription({ name, description }) {
  return (
    <div className="flex flex-col gap-1 px-5 py-3 border border-b-border-grey">
      <div className="text-secondary hover:cursor focus:cursor">
        <CustomLink to={HOME_PAGE_PATH}>
          <i className="ri-arrow-left-s-line text-base"></i>
          <span className="text-sm text-bold">Browse Care Models</span>
        </CustomLink>
      </div>
      <div className="font-bold">
        {name}
      </div>
      <div className="text-sm text-secondary-grey">
        {description}
      </div>
    </div>
  )
}

export default PageDescription