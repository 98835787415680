const TriageLevelAttributeMapping = {
  'Emergency': {
    backgroundColor: 'bg-light-red',
    textColor: 'text-red'
  },
  'Call EMS 911 Now': {
    backgroundColor: 'bg-light-red',
    textColor: 'text-red',
    icon: 'ri-alarm-warning-fill'
  },
  'Go To ED Now': {
    backgroundColor: 'bg-light-red',
    textColor: 'text-red',
    icon: 'ri-alarm-warning-fill'
  },
  'Urgent': {
    backgroundColor: 'bg-light-orange',
    textColor: 'text-orange',
    icon: 'ri-alarm-warning-fill'
  },
  'Schedule Now': {
    backgroundColor: 'bg-light-orange',
    textColor: 'text-orange',
    icon: 'ri-alarm-warning-fill'
  },
  'Schedule Today': {
    backgroundColor: 'bg-light-orange',
    textColor: 'text-orange',
    icon: 'ri-alarm-warning-fill'
  },
  'Schedule Tomorrow': {
    backgroundColor: 'bg-light-orange',
    textColor: 'text-orange',
    icon: 'ri-alarm-warning-fill'
  },
  'Delayed': {
    backgroundColor: 'bg-light-green',
    textColor: 'text-primary',
    icon: 'ri-alarm-warning-fill'
  },
  'Schedule within 3 Days': {
    backgroundColor: 'bg-light-green',
    textColor: 'text-primary',
    icon: 'ri-alarm-warning-fill'
  },
  'Home Care': {
    backgroundColor: 'bg-light-green',
    textColor: 'text-primary',
    icon: 'ri-alarm-warning-fill'
  },
  'Default': {
    backgroundColor: 'bg-light-green',
    textColor: 'text-primary',
    icon: 'ri-alarm-warning-fill'
  }
}

export { TriageLevelAttributeMapping }