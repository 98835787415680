// import { getAccessToken } from "../lib/local-storage";
import {
  // CUSTOM_SUMMARY_QA_URL,
  CUSTOM_SUMMARY_QA_V1_URL,
} from "../lib/api-urls";
import { crmcAxiosInstance } from "../lib/axios";
import { v4 as uuidv4 } from "uuid";
// const accessToken = getAccessToken();

async function getCustomSummary({ chatHistory, document, question }) {
  try {
    // 
    return await crmcAxiosInstance.post(
      CUSTOM_SUMMARY_QA_V1_URL,
      {
        model_platform: "openai",
        model_temperature: 0,
        model_type: "qa",
        prompt_key: "NUHEALTH_SUMMARIZATION",
        model_name: "gpt-4o",
        input_variables: {
          context: document,
          question: question,
          chat_history: chatHistory,
        },
        session_id: uuidv4(),
      }
    );
  } catch (e) {
    return e;
  }
}

async function getCustomSummaryV1({ chatHistory, document, question }) {
  try {
    return await crmcAxiosInstance.post(CUSTOM_SUMMARY_QA_V1_URL, {
      model_platform: "openai",
      model_temperature: 0,
      model_type: "chat",
      prompt_key: "NUHEALTH_QA",
      model_name: "gpt-4o",
      input_variables: {
        context: document,
        question: question,
        chat_history: chatHistory,
      },
      session_id: sessionStorage.getItem("sessionId"),
    });
  } catch (e) {
    return e;
  }
}

export { getCustomSummary, getCustomSummaryV1 };
