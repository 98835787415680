const DIRECT_TRIAGE_LEVEL_URL = `/triage/directlevel`;
const TRIAGE_LEVEL_URL = `/triage/level`;
const TRIAGE_QUESTIONS_URL = `/triage/questions`;
const TRIAGE_CHAT_URL = `/triage/chat`;
const CUSTOM_SUMMARY_URL = `/summary/custom`;
const CUSTOM_SUMMARY_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/summarization/v1`;
const CUSTOM_SUMMARY_QA_URL = `/qa`;
const CUSTOM_SUMMARY_QA_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/qa/v1`;
const CODING_MEDICAL_CONCEPTS_URL = `/coding/medicalconcepts`;
const BEHAVIORAL_TRIAGE_DISEASES_URL = `/btriage/diseases`;
const BEHAVIORAL_TRIAGE_QUESTIONS_URL = `/btriage/questions`;
const BEHAVIORAL_TRIAGE_LEVEL_URL = `/btriage/triagelevel`;
const LOGIN_URL = `/login`;
const SIGNUP_URL = `/sign-up`;
const TRIAGE_CHAT_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/conversation/v1`;
const CHAT_HISTORY_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/chat_history/v1`;
const TRIAGE_QUESTIONS_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/instruction/v1`;
const TRIAGE_LEVEL_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/instruction/v1`;
const BEHAVIORAL_TRIAGE_QUESTIONS_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/instruction/v1`;
const BEHAVIORAL_TRIAGE_LEVEL_V1_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/llm/agent/instruction/v1`;
const USERS_URL = `${
  process.env.REACT_APP_SUMMARIZATION_API_URL || "https://apps.nuscale.io:8001"
}/nuscale/data/search/v1`;

export {
  DIRECT_TRIAGE_LEVEL_URL,
  TRIAGE_LEVEL_URL,
  TRIAGE_LEVEL_V1_URL,
  TRIAGE_QUESTIONS_URL,
  TRIAGE_QUESTIONS_V1_URL,
  TRIAGE_CHAT_URL,
  CUSTOM_SUMMARY_URL,
  CUSTOM_SUMMARY_V1_URL,
  CUSTOM_SUMMARY_QA_URL,
  CUSTOM_SUMMARY_QA_V1_URL,
  CODING_MEDICAL_CONCEPTS_URL,
  BEHAVIORAL_TRIAGE_DISEASES_URL,
  BEHAVIORAL_TRIAGE_QUESTIONS_URL,
  BEHAVIORAL_TRIAGE_QUESTIONS_V1_URL,
  BEHAVIORAL_TRIAGE_LEVEL_URL,
  LOGIN_URL,
  SIGNUP_URL,
  TRIAGE_CHAT_V1_URL,
  CHAT_HISTORY_URL,
  BEHAVIORAL_TRIAGE_LEVEL_V1_URL,
  USERS_URL
};
