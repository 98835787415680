import { crmcAxiosInstance } from '../lib/axios'
import { v4 as uuid } from 'uuid'
import { TRIAGE_CHAT_V1_URL, CHAT_HISTORY_URL } from '../lib/api-urls'
import { AI_MESSAGE_TYPE } from '../lib/constants'

async function getInitialChatMessage() {
  try {
    const response = await crmcAxiosInstance.get(
      CHAT_HISTORY_URL,
      {
        params: {
          session_id: sessionStorage.getItem("sessionId"),
        }
      }
    )
    console.log(response)
    return {
      data: response.data.chat_history?.chat_memory?.messages
    }
  } catch (e) {
    return {
      data: [
        {
          initialMessage: 'Hello, Can you please tell me the age and gender of the patient?',
          type: AI_MESSAGE_TYPE
        }
      ]
    }
  }
}

async function getTriageChatV1({ chatHistory, question }) {
  try {
    return await crmcAxiosInstance.post(
      TRIAGE_CHAT_V1_URL,
      {
        model_platform: "openai",
        model_temperature: 0,
        prompt_key: "NUHEALTH_CHAT_PROMPT",
        model_name: "gpt-4o",
        model_type: "chat",
        input_variables: {
          context: 'healthcare related questions',
          question: question,
          chat_history: chatHistory,
        },
        session_id: sessionStorage.getItem("sessionId"),
      })
  } catch (e) {
    return e
  }
}

export { getInitialChatMessage, getTriageChatV1 }