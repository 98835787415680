import { getBTriageLevelV1 } from '../../../api/behavioral-triage'
import CustomButton from '../../../components/CustomButton'
import generatePatientInfo from '../../../lib/generate-patient-info'
import toast from '../../../lib/toast'

function SupplementaryQuestions({
  data = {},
  patientInfoObject = {},
  goToPreviousStep,
  goToNextStep,
  updateDataForStep,
  setIsLoading
}) {
  const formFields = data.fields.map(function (field, index) {
    let fieldComponent
    switch (field.questionType) {
      case 'select-option':
        fieldComponent = (
          <select onChange={(e) => handleChange(index, e.target.value)} className="rounded border border-border-grey p-2" value={field.value}>
            <option key={`empty-${index}`}></option>
            {
              field.options.map(function (option) {
                return <option key={option + `-${index}`} value={option}>{option}</option>
              })
            }
          </select>
        )
        break
      case 'text':
        fieldComponent = (
          <input
            type="text"
            onChange={(e) => handleChange(index, e.target.value)}
            className="rounded border border-border-grey p-2"
            value={field.value}
          />
        )
        break
      case 'textarea':
        fieldComponent = (
          <textarea
            onChange={(e) => handleChange(index, e.target.value)}
            className="rounded border border-border-grey p-2 resize-none"
            value={field.value}
          />
        )
        break
      default:
        fieldComponent = <></>
        break
    }

    return (
      <div className="flex flex-col bg-white p-4 gap-2" key={index}>
        <div className="text-tertiary text-sm font-medium">{field.question}</div>
        {fieldComponent}
      </div>
    )
  })

  let questionsAnsweredCount = 0

  data.fields.forEach(function (field) {
    if (field.value) {
      questionsAnsweredCount++
    }
  })

  function handleChange(fieldIndex, value) {
    const fields = data.fields
    const updatedFields = fields.map(function (field, index) {
      if (index === fieldIndex) {
        return { ...field, value }
      }

      return field
    })

    // changeStep(1, { fields: updatedFields })
    updateDataForStep({ data: { fields: updatedFields } })
  }

  function generatePatientInfoWithQuestions() {
    let answers = ''
    // eslint-disable-next-line
    data.fields.map(function (field, index) {
      answers += `${index + 1}. ${field.question}: ${field.value || ''} \n`
    })
    console.log(patientInfoObject)
    return generatePatientInfo({ ...patientInfoObject.data, mainComplaint: patientInfoObject.data.diseaseSelected }) + "\n Questions and Answers: \n" + answers
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    const { data: response, error } = await getBTriageLevelV1({
      patientInfo: generatePatientInfoWithQuestions()
    })
    if (error) {
      toast({ message: error.message })
    } else {
      console.log(response)
      goToNextStep({ nextStepIndex: 2, nextStepData: { data: response } })
    }
    setIsLoading(false)
  }

  function goBack() {
    goToPreviousStep({ previousStepIndex: 0 })
    // changeCurrentStep(0)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col bg-white rounded border border-near-white">
        <div className="flex flex-col p-4 gap-2">
          <div className="font-bold">Supplementary Questions ({questionsAnsweredCount} out of {data.fields.length} answered)</div>
          <div className="text-xs text-tertiary">Use this section to answer questions that would help with the patient’s diagnosis</div>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-2 max-h-[calc(100vh-410px)] overflow-scroll">
        {formFields}
      </div>
      <div className="flex flex-col mt-2">
        <div className="flex flex-row justify-end gap-2">
          <CustomButton onClick={goBack} buttonType='secondary' additionalStyle="px-4 py-2">Back</CustomButton>
          <CustomButton type="submit" additionalStyle="px-4 py-2">Next</CustomButton>
        </div>
      </div>
    </form>
  )
}

export default SupplementaryQuestions