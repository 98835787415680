import { useEffect, useState, Fragment } from "react";
import debounce from "debounce";
import { v4 as uuidv4 } from "uuid";
import AuthenticatedLayout from "../../components/layouts/AuthenticatedLayout";
import {
  HUMAN_MESSAGE_TYPE,
  AI_MESSAGE_TYPE,
  AI_LABEL,
  USER_LABEL,
} from "../../lib/constants";
import PageDescription from "../../components/PageDescription";
import Loader from "../../assets/gif/loader.svg";
import { getInitialChatMessage, getTriageChatV1 } from "../../api/triage-chat";
import CustomButton from "../../components/CustomButton";
import toast from "../../lib/toast";
import MarkdownRenderer from "react-markdown-renderer";

const initialState = {
  messages: [],
  currentMessage: "",
  isLoading: false,
};
function ChatPage() {
  const [state, setState] = useState(initialState);
  const [whoIsTyping, setWhoIsTyping] = useState("");

  const handleIsTyping = debounce(function () {
    setWhoIsTyping("");
  }, 500);

  function sendMessage() {
    if (!state.currentMessage) {
      return;
    }
    const currentMessages = state.messages;
    const nextMessage = {
      content: state.currentMessage,
      type: HUMAN_MESSAGE_TYPE,
    };
    setState({
      ...state,
      isLoading: true,
      currentMessage: "",
      messages: [...currentMessages, nextMessage],
    });
    setTimeout(function () {
      scrollToBottom();
    }, 100);
    setWhoIsTyping(AI_LABEL);
    async function sendMessage() {
      const { data, error } = await getTriageChatV1({
        chatHistory: [...currentMessages],
        question: state.currentMessage,
      });
      if (error) {
        toast({ message: error.message });
        setState({
          ...state,
          isLoading: false,
          currentMessage: "",
          messages: [...currentMessages, nextMessage],
        });
      } else {
        setState({
          ...state,
          isLoading: false,
          currentMessage: "",
          messages: [
            ...currentMessages,
            {
              question: state.currentMessage,
              answer: data.text,
            },
          ],
        });
      }
      setWhoIsTyping("");
      setTimeout(function () {
        scrollToBottom();
      }, 100);
    }
    sendMessage();
  }

  function handleCurrentMessageChange(currentMessage) {
    setState({ ...state, currentMessage });
    setWhoIsTyping(USER_LABEL);
    handleIsTyping();
  }

  function scrollToBottom() {
    const element = document.getElementById("scrollable-parent");
    element.scrollTop = element.scrollHeight;
  }

  function getAIMessage(text, index) {
    return (
      <div key={"ai-chat-message-" + index} className="flex flex-row">
        <div className="flex flex-row basis-2/3 justify-start items-center gap-3">
          <div className="px-2 py-1 rounded-lg bg-bg-grey border border-border-grey">
            <i className="ri-robot-2-fill text-primary text-3xl"></i>
          </div>
          <div className="flex text-tertiary p-4 rounded-r-xl rounded-bl-xl bg-bg-grey border border-border-grey items-center">
            <MarkdownRenderer markdown={text} />
          </div>
        </div>
      </div>
    );
  }

  function getHumanMessage(text, index) {
    return (
      <div key={"human-chat-message-" + index} className="flex flex-row">
        <div className="basis-1/3"></div>
        <div className="flex flex-row basis-2/3 justify-end items-center gap-3">
          <div className="flex text-tertiary py-3 px-5 rounded-l-xl rounded-tr-xl bg-primary text-white items-center text-sm">
            {text}
          </div>
        </div>
      </div>
    );
  }

  function getChatComponent() {
    return state.messages.map(function (message, index) {
      let humanMessage, aiMessage;
      if (message.question)
        humanMessage = getHumanMessage(message.question, index);
      if (message.answer) aiMessage = getAIMessage(message.answer, index);
      return (
        <Fragment key={"chat-" + index}>
          {humanMessage}
          {aiMessage}
        </Fragment>
      );
    });
  }

  function handleKeyDown(event) {
    if (event.key === "Enter" && !state.isLoading) {
      sendMessage();
    }
  }

  const chatComponent = getChatComponent();

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col grow bg-lightgrey">
        <PageDescription
          name="Chat"
          description="Determine the triage level of a patient based on symptoms by chatting with an AI Assistant"
        />
        <div className="flex flex-row justify-between items-center p-4 bg-white rounded-md border-lightgrey border-2">
          <div>
            <div className="text-lg">New Chat</div>
            <div className="text-secondary-grey text-sm">
              {whoIsTyping ? whoIsTyping + " typing..." : <>&nbsp;</>}
            </div>
          </div>
          <div>
            <i className="ri-more-2-fill text-2xl"></i>
          </div>
        </div>
        <div className="flex flex-col grow gap-3 p-4 max-h-[calc(100vh-274px)] h-[100%] w-[100%]">
          <div
            className="flex flex-col bg-white max-h-[calc(100vh-352px)] h-[100%] w-[100%] p-4 gap-3 overflow-y-auto"
            id="scrollable-parent"
          >
            {chatComponent}
            {state.isLoading && (
              <div className="flex flex-col justify-center items-center">
                <img src={Loader} className="w-[50px]" alt="loading" />
              </div>
            )}
            {/* <div className="hidden" ref={messagesEndRef} /> */}
          </div>
          <div className="flex flex-row items-center p-4 bg-white gap-2 border-lightgrey border-2">
            <div className="flex grow">
              <input
                className="w-[100%] text-sm text-tertiary rounded-full py-2 px-3 border border-border-grey focus:border-border-grey"
                type="text"
                placeholder="Type a message..."
                value={state.currentMessage}
                onChange={(e) => handleCurrentMessageChange(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={state.isLoading}
              />
            </div>
            <i className="ri-mic-line text-xl text-tertiary"></i>
            <CustomButton
              additionalStyle="px-2 py-1"
              rounded="rounded-full"
              onClick={sendMessage}
              disabled={state.isLoading || !state.currentMessage}
            >
              <i className="ri-send-plane-fill text-white text-xl"></i>
            </CustomButton>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  );
}

export default ChatPage;
