import React from "react";
import LoginCallback from "./auth/components/LoginCallback";
import Logout from "./auth/components/Logout";
import Login from "./auth/components/Login";
import {
  HOME_URL,
  LOGIN_CALLBACK_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
} from "./auth/config";
import {
  ModelSettingsPage,
  MedicalTriagePage,
  BehavioralTriagePage,
  ChatPage,
  SummarizationPage,
  ChartsQAPage,
  MedicalCodingPage,
  HomePage,
} from "./pages";
import {
  MEDICAL_TRIAGE_PAGE_PATH,
  BEHAVIORAL_TRIAGE_PAGE_PATH,
  CHAT_PAGE_PATH,
  SUMMARIZATION_PAGE_PATH,
  CHARTS_QA_PAGE_PATH,
  MEDICAL_CODING_PAGE_PATH,
  MODEL_SETTING_PAGE_PATH,
} from "../src/lib/paths";

const routes = [
  {
    path: HOME_URL,
    element: <HomePage />,
    isPrivate: true,
  },
  // Auth specific routes

  {
    path: LOGIN_CALLBACK_PATH,
    element: <LoginCallback />,
  },
  {
    path: LOGOUT_PATH,
    element: <Logout />,
  },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    isPrivate: true,
    path: MEDICAL_TRIAGE_PAGE_PATH,
    element: <MedicalTriagePage />,
  },
  {
    isPrivate: true,
    path: BEHAVIORAL_TRIAGE_PAGE_PATH,
    element: <BehavioralTriagePage />,
  },
  {
    isPrivate: true,
    path: CHAT_PAGE_PATH,
    element: <ChatPage />,
  },
  {
    isPrivate: true,
    path: SUMMARIZATION_PAGE_PATH,
    element: <SummarizationPage />,
  },
  {
    isPrivate: true,
    path: CHARTS_QA_PAGE_PATH,
    element: <ChartsQAPage />,
  },
  {
    isPrivate: true,
    path: MEDICAL_CODING_PAGE_PATH,
    element: <MedicalCodingPage />,
  },
  {
    isPrivate: true,
    path: MODEL_SETTING_PAGE_PATH,
    element: <ModelSettingsPage />,
  },
];

export default routes;
